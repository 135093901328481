/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   slider
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initSlider = function() {

        /** change categorie (male or female) **/
        function changeCategory() {
            var trigger = $('.js-switch');
            var slide = $('.c-slider__slide');
            var spin = '<span class="e-loader"></span>';
            var wrap = $('.c-slider__wrap');

            trigger.on('click', function(e){
                e.preventDefault();

                var self = $(this);
                var type = self.data('type');
                var item = $('#' + type);

                changePicture();

                if(! self.hasClass('is--active')){
                    trigger.removeClass('is--active');
                    self.addClass('is--active');
                }

                wrap.append(spin).delay(50).queue(function(){
                    wrap.addClass('is--loading').delay(800).queue(function(){
                        slide.removeClass('is--active');
                        item.addClass('is--active');
                        wrap.removeClass('is--loading').delay(100).queue(function(){
                            $('.e-loader').remove();
                            $(this).dequeue();
                        });
                        $(this).dequeue();
                    });
                    $(this).dequeue();
                });
            });
        }

        /** change categorie pictures **/
        function changePicture() {
            var button = $('.js-color');
            var url;

            button.on('click', function(){
                var self = $(this);
                var wrap = self.parents('.c-slider__slide');
                var flap = self.parents('.c-slider__nav').find('.js-flap');
                var buttons = self.parents('.c-slider__nav').find('.is--active');
                var color = self.data('color');
                var type = self.parents('.c-slider__slide').attr('id');
                //var position = self.parents('.js-holder').data('pos');
                var image = wrap.find('img');

                if(! self.hasClass('is--active')) {
                    buttons.removeClass('is--active');
                    self.addClass('is--active');
                }

                var top = wrap.find('.js-top');
                var bottom = wrap.find('.js-bottom');
                var activeTop = top.find('.is--active').data('color');
                var activeBottom = bottom.find('.is--active').data('color');

                if(activeTop === activeBottom) {
                    url = "assets/dist/img/content/" + type + "/both/" + color + ".jpg";
                }else {
                    url = "assets/dist/img/content/" + type + "/both/" + activeTop + "_" + activeBottom + ".jpg";
                }

                flap.attr('data-state', color);
                image.attr('src', url);
            });
        }

        /** open color **/
        function openColor() {
            $('.js-flap').on('click', function(e){
                e.preventDefault();

                var self = $(this);
                var wrap = self.parents('.c-slider__nav');

                if(wrap.hasClass('is--open')) {
                    wrap.removeClass('is--open');
                }else {
                    wrap.addClass('is--open');
                }
            });
        }

        $(document).ready(function () {
            changeCategory();
            openColor();
            changePicture();
        });
    };
})(jQuery);