/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Table
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initLayer = function() {
        $(this).on('click', function(){
            var layer = $('.e-layer');

            if(layer.hasClass('is--open')) {
                layer.removeClass('is--open');
            }else {
                layer.addClass('is--open');
            }
        });
    };
})(jQuery);