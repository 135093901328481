/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Internet Explorer 10 doesn't differentiate device width from viewport width
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initFeatureDetection = function() {
        var doc = document.body || document.documentElement;
        var style = doc.style;
        if ( style.webkitFlexWrap == '' ||
            style.msFlexWrap == '' ||
            style.flexWrap == '' ) {
          doc.className += " is--flex";
        }else {
            doc.className += " no--flex";
        }

    };
})(jQuery);