/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Match height
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initmatchHeight = function() {
        var self = $(this);
        var equalheight;
        var current;

        equalheight = function (container) {
            var _iCurrentTallest = 0,
                _iCurrentRowStart = 0,
                _iRowDivs = new Array(),
                el,
                _oTopPosition = 0;

            $(container).each(function () {

                el = $(this);
                $(el).height('auto');
                _oTopPosition = el.position().top;

                if (_iCurrentRowStart != _oTopPosition) {
                    for (current = 0; current < _iRowDivs.length; current++) {
                        _iRowDivs[current].height(_iCurrentTallest);
                    }
                    _iRowDivs.length = 0; // empty the array
                    _iCurrentRowStart = _oTopPosition;
                    _iCurrentTallest = el.height();
                    _iRowDivs.push(el);
                } else {
                    _iRowDivs.push(el);
                    _iCurrentTallest = (_iCurrentTallest < el.height()) ? (el.height()) : (_iCurrentTallest);
                }
                for (current = 0; current < _iRowDivs.length; current++) {
                    _iRowDivs[current].height(_iCurrentTallest);
                }
            });
        };

        $(window).on('load', function () {
            equalheight(self);
        });

        $(window).resize(function () {
            equalheight(self);
        });

        window.addEventListener("orientationchange", function () {
            equalheight(self);
        });

    };
})(jQuery);