/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Table
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initResponsiveTable = function() {

        /*eslint-disable*/
        $(this).each(function () {
            var self = $(this);
            var headertext = [];
            var headers = self.find("thead");
            var tablebody = self.find("tbody");

            if (headers.length) {
                for (var i = 0; i < headers.length; i++) {
                    headertext[i] = [];
                    for (var j = 0, headrow; headrow = headers[i].rows[0].cells[j]; j++) {
                        var current = headrow;
                        headertext[i].push(current.textContent);
                    }
                }

                for (var h = 0, tbody; tbody = tablebody[h]; h++) {
                    for (var i = 0, row; row = tbody.rows[i]; i++) {
                        for (var j = 0, col; col = row.cells[j]; j++) {
                            col.setAttribute("data-th", headertext[h][j]);
                        }
                    }
                }
            }
        });
        /*eslint-enable */

    };
})(jQuery);