/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   article change
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initArticleChange = function() {
        var categoryTrigger = $('.js-article-trigger');
        var category = $('.js-article-category');
        var articleWrap = $('.js-article-wrap');
        var spin = '<span class="e-loader"></span>';

        /** change view **/
        categoryTrigger.on('click', function(){
            var self = $(this);
            var type = self.data('type');

            if(!self.hasClass('is--active')) {
                categoryTrigger.removeClass('is--active');
                self.addClass('is--active');
            }

            setColors(type);
            showText(type);

            category.each(function(){
                var el = $(this);
                var data = el.data('type');

                if(type === data) {
                    var actCat = $(this);

                    if(! actCat.hasClass('is--active')) {
                        articleWrap.append(spin).delay(50).queue(function(){
                            articleWrap.addClass('is--loading').delay(800).queue(function(){
                                category.removeClass('is--active');
                                actCat.addClass('is--next');
                                changeImage();
                                actCat.removeClass('is--next').delay(50).queue(function(){
                                   actCat.addClass('is--active');
                                   $(this).dequeue();
                                });
                                articleWrap.removeClass('is--loading').delay(100).queue(function(){
                                    $('.e-loader').remove();
                                    $(this).dequeue();
                                });
                                $(this).dequeue();
                            });
                            $(this).dequeue();
                        });
                    }
                }
            });
        });

        /** show text **/
        function showText(type) {
            var textWrap = $('.c-text__desc');

            textWrap.each(function(){
                if($(this).hasClass(type)){
                    textWrap.fadeOut();
                    $(this).fadeIn();
                }
            });
        }

        /** add id **/
        function setColors(type) {
            $('.c-colorpicker__list').attr('id', type);
        }

        /** set active color image **/
        function changeImage() {
            var wrap = $('.js-article-wrap');
            var list = $('.c-colorpicker__list');
            var id = list.attr('id');
            var button = list.find('.is--active');
            var color = button.data('color');
            var categorie = button.parents('section').attr('id');
            var active = wrap.find('.is--next');
            var image = active.find('img');
            var url = "assets/dist/img/content/" + categorie + "/" + id + "/" + color + ".jpg";

            image.attr('src', url);
        }

        $(document).ready(function () {
            setColors();
        });
    };
})(jQuery);