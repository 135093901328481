/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Fine
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @author     Brian Stark <brian.stark@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

/*var breakpoints = {
   'handheld': 340,
   'xsmall': 640,
   'small': 768,
   'medium': 1024,
   'normal': 1160,
   'wide': 1280,
   'large': 1440,
   'xlarge': 1600
};*/

var Main = {

    /**
     * init/constructor method
     */
    init : function() {
        this.domReady();
        this.onLoad();
		this.onResize();
        this.onOrientationchange();
    },

    /**
     * Binding for domReady-Event
     * Note: we don't need to wrap this with $(function() {}) because
     * at this stage we effectively have parsed the whole DOM already
     */
    domReady : function() {
        var that = this;
        $(document).ready(function() {
            setTimeout(function() {
                $('html').removeClass('no-transition');
            },300);

            /** Internet Explorer 10 doesn't differentiate device width from viewport width **/
            $().initIEDeviceHandling();

            /** init CSS feature detection **/
            $().initFeatureDetection();

            /** init toggle layer **/
            $('.js-layer-toggle').initLayer();
            
            /** init rnav layer **/
            $('.js-nav-toggle').initResponsiveNav();

            /** init article change **/
            $().initArticleChange();

            /** init color picker **/
            $().initColorPicker();

            /** init product slider **/
            $().initSlider();

            /** init js match height for older browser **/
            $('.no--flex .js-col-match, .js-match').initmatchHeight();

            /** init responsive breadcrumb **/
            $('.js-breadcrumb').initBreadcrumb();

            /** init responsive table unction **/
            $('table').initResponsiveTable();

            /** init responsive pagination **/
            $('.js-pagination').initPagination();

            /** input focus handling (validate + holders) **/
            if($('.js-form').length) {
                $('input, textarea').initJSValidate();
            }

            window.sr = ScrollReveal();
            sr.reveal('.foo');

            /** init config stuff **/
            that.transitionResizeHandling();
        });
    },

    /**
     * Binding for onLoad-Event
     */
    onLoad : function(){
        $(window).on('load', function() {
            //Stuff
        });
    },

	/**
     * Binding for onResize-Event
     */
    onResize : function() {
        var iWidth = $(window).width();
        $(window).on('resize', function() {
            if($(window).width() != iWidth) {
                //Stuff
                iWidth = $(window).width();
            }
        });
    },

    /**
     * Binding for onOrientationchange-Event
     */
    onOrientationchange : function() {
        $(window).on('orientationchange', function() {
            //Stuff
        });
    },

    /**
     * transition resize handling
     */
    transitionResizeHandling : function() {
        var _oResizeId;
        var _oHtml = $('html');
        $(window).on('resize', function() {
            clearTimeout(_oResizeId);
            _oHtml.addClass('no-transition');
            _oResizeId = setTimeout(doneResizing, 500);
        });

        function doneResizing() {
            _oHtml.removeClass('no-transition');
        }
    }
};

// Init
Main.init();
