/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   color picker
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initColorPicker = function() {
        var trigger = $('.js-color-picker');
        var wrap = $('.js-article-wrap');

        trigger.on('click', function(){
            var self = $(this);
            var name = self.attr('aria-label');
            var list = self.parents('ul');
            var items = list.find('.js-color-picker');
            var title = $('.js-color-title');

            title.text(name);

            changeImage(self);

            if(! self.hasClass('is--active')) {
                items.removeClass('is--active');
                self.addClass('is--active');
            }
        });

        function changeImage(el) {
            var button = el;
            var color = button.data('color');
            var id = button.parents('ul').attr('id');
            var categorie = button.parents('section').attr('id');
            var active = wrap.find('.is--active');
            var image = active.find('img');
            var url = "assets/dist/img/content/" + categorie + "/" + id + "/" + color + ".jpg";

            image.attr('src', url);
        }
    };
})(jQuery);