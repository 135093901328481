/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Table
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initJSValidate = function() {
        var field = $(this);

        setTimeout(function(){
           field.each(function(){
                if($(this).val().trim() !== ''){
                    $(this).addClass('is--filled');
                }
            });
        }, 300);

        field.focus(function(){
            $(this).addClass('is--filled');
        });

        field.blur(function(e){
            if(e.target.value.trim() === '') {
                $(this).removeClass('is--filled');
            }
        });
    };
})(jQuery);