/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Scroll to top button
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initScrollToTopButton = function() {
        var body = $('body');
        var scroller = $('.js-scroll-top');
        var scroll = $(window).scrollTop();
        var offset = 320;
        var duration = 300;
        var button = '<button class="e-btn e-btn--scroll js-scroll-top" type="button" role="button" title="Zum Seitenanfang springen" aria-label="Zum Seitenanfang springen"></button>';

        if (scroll > offset) {
            if (! body.find('.e-btn--scroll').length) {
                $(button).appendTo(body).hide().fadeIn(duration);
            }
        } else {
            scroller.fadeOut(duration).delay(50).queue(function () {
                $(this).remove();
                $(this).dequeue();
            });
        }
    };
})(jQuery);